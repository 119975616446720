import React from 'react';
import corsoLogo  from '../imgs/corso-logo-black.png';
import facebookIcon from '../imgs/facebook-icon.svg'
import instagramIcon from '../imgs/instagram-icon.svg'

export default function Footer({footerBackground, buttonColor, brochureDownload}) {

   
  return (
    <div className='footer-container' style={{backgroundColor:`${footerBackground}`}}>
      <div className='footer-div'>
        <div className='footer-header-div'>
            <img alt="corso logo" className='corso-logo' src={corsoLogo} />
            <div className='social-media-container'>
            <a rel="noreferrer" href='https://www.facebook.com/corsoatlanta/' target="_blank" className='facebook-icon' style={{backgroundColor:`${buttonColor}`}} data-testid='footer-facebook-btn'>
                <img alt="" className='facebook' src={facebookIcon} />
            </a>
            <a href='https://www.instagram.com/corsoatlanta/?hl=en' rel="noreferrer" target="_blank" className='instagram-icon' style={{backgroundColor:`${buttonColor}`}} data-testid='footer-instagram-btn'>
                <img alt="" className='instagram' src={instagramIcon} />
            </a>
            </div>
        </div>
     
      <div className='footer-line' />
    <div className='footer-columns-div'>
        <div className='footer-column'>
            <p className='footer-title'>Contact Us</p>
            <div className='footer-description'>
            <a href="http://maps.google.com/?q=3200 Howell MIll Rd NW Atlanta, GA 30327"  rel="noreferrer" target="_blank" className='footer-address' data-testid='footer-address-link'>
                3200 Howell MIll Rd NW <br/>Atlanta, GA 30327
            </a>
            <a href='tel:+14048919190' className='footer-phone-number'  data-testid='footer-phone-link'>
            (404) 891-9190
            </a>
            <a href='mailto:referralscorso@corsoatlanta.com' rel="noreferrer" target="_blank" className='footer-email'  data-testid='footer-email-link'>
                referralscorso&#64;corsoatlanta.com
            </a>
            </div>
        </div>
        <div className='footer-line' />

        <div className='footer-column'>
            <p className='footer-title'>
            Affiliate Communities
            </p>
            <div className='footer-description'>
            <a href='https://villageparkmilton.com/' target="_blank" rel="noreferrer" className='footer-link' data-testid='footer-vp-milton-link'>
                village park milton
            </a>
            <a href='https://villageparkalpharetta.com/' target="_blank" rel="noreferrer" className='footer-link'  data-testid='footer-vp-alpharetta-link'>
                village park alpharetta
            </a>
            <a href='https://villageparkpeachtreecorners.com/' target="_blank" rel="noreferrer" className='footer-link'  data-testid='footer-vp-peachtree-link'>
                village park peachtree corners
            </a>
            </div>
        </div>
        <div className='line10' />
        <div className='footer-column'>
            <p className='footer-title'>
            More Information
            </p>
            <div className='footer-description'>
            <a href='https://galerieliving.com/' target='_blank' rel="noreferrer" className='footer-link'  data-testid='footer-corporate-link'>Corporate</a>
            <p onClick={()=>brochureDownload()} className='brochure'  data-testid='footer-brochure-link'>
                Download a brochure
            </p>
            </div>
        </div>
        </div>
        </div>
        <div className='copyright'>
            <p className='copyright-description'>
            Copyright © 2023 Corso Atlanta | All Rights
            Reserved | <a href='https://corsoatlanta.com/privacy-policy/' target='_blank' rel='noreferrer'  data-testid='footer-terms-conditions-link'>Terms and Conditions</a> | 
            <a style={{marginLeft:'2px'}} href='https://corsoatlanta.com/privacy-policy/' target='_blank' rel='noreferrer' data-testid='footer-privacy-policy-link'>Privacy Policy</a>
            </p>
        </div>
        
    </div>
  )
  }
