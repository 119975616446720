



import React, {  useState } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import EmailIcon from '../icons/EmailIcon';
import MapPinIcon from '../icons/MapPinIcon';
import PhoneIcon from '../icons/PhoneIcon';
import {  Element } from 'react-scroll';
import Quiz from './Quiz';




export default function HeroBlockDesignB({brochureDownload, db, setExperimentA, experimentA}) {
    const max_width = useMediaPredicate("(max-width: 875px)");
    const small_quiz_width = useMediaPredicate("(max-width: 559px)");
    const [emailHover, setEmailHover]=useState('none')
    const [phoneHover, setPhoneHover]=useState('none')
    const [addressHover, setAddressHover]=useState('none')
    const [goToQuiz, setGoToQuiz]=useState(false);
    const handleGoToQuiz=()=>{
      // this event push tells analytics that the user started the quiz in version A of the A/B test 
        setExperimentA(true)
        setGoToQuiz(true)
        const anchor = document.querySelector('#quizDivMobile');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    
  
  return (
    <>
    {max_width?<>
        <div className='hero-information'>
          <p className='hero-title'>
          Atlanta's Most <i>Luxurious</i><br/> Senior Living Community
          </p>
          <div className='contact-specifics'>
            <a className='email-button' onMouseOver={()=>setEmailHover('black')} onMouseOut={()=>setEmailHover('black')} href='mailto:referralscorso@corsoatlanta.com' target='_blank' rel='noreferrer'>
              <EmailIcon style={{fill:'black'}}/>
            </a>
            <a className='phone-button' href='tel:+14048919190' onMouseOver={()=>setPhoneHover('black')} onMouseOut={()=>setPhoneHover('black')} target='_blank' rel='noreferrer'>
              <PhoneIcon style={{fill:'black'}}/>
            </a>
            <a href="http://maps.google.com/?q=3200 Howell Mill Rd NW Atlanta, GA 30327" className='address-button' onMouseOver={()=>setAddressHover('black')} onMouseOut={()=>setAddressHover('black')} target='_blank' rel='noreferrer'>
                  <MapPinIcon style={{fill:'black'}}/>
            </a>
       
     </div>
     <div className='quiz-div-mobile' id='quizDivMobile'>
    
      <Element id='scrollQuiz' name='scrollQuiz'>
         <Quiz brochureDownload={brochureDownload} db={db} setExperimentA={setExperimentA} experimentA={experimentA}/>
         </Element>
     
    
      </div>
   </div>
    </>:<>
    <div className='hero-block'>
      <div className='hero-column'>
        <div className='hero-information'>
          <div className='hero-title-div'>
          <p className='hero-title'>
          Atlanta's Most <br/> <i>Luxurious</i><br/> Senior Living <br/>Community
          </p>
          </div>
          <div className='contact-specifics'>
            <a className='email-button2' href='mailto:referralscorso@corsoatlanta.com' onMouseOver={()=>setEmailHover('underline')} onMouseOut={()=>setEmailHover('none')} data-testid='hero-block-email-btn'>
              <div className='email-icon' >
                <EmailIcon style={{fill:'black'}}/>
              </div>
              <p className='email-text' style={{textDecoration:emailHover}}>
                referralscorso&#64;corsoatlanta.com
              </p>
             
            </a>
            <a className='phone-button2' href='tel:+14048919190' onMouseOver={()=>setPhoneHover('underline')} onMouseOut={()=>setPhoneHover('none')} data-testid='hero-block-phone-btn'>
              <div className='phone-icon'>
                <PhoneIcon style={{fill:'black'}}/>
              </div>
              <p className='email-text' style={max_width?{textDecoration:phoneHover}:{textDecoration:'none'}}>
              (404) 891-9190
              </p>
            </a>
            <a target='_blank' className='address-button2' href='http://maps.google.com/?q=3200 Howell Mill Rd NW Atlanta, GA 30327' onMouseOver={()=>setAddressHover('underline')} onMouseOut={()=>setAddressHover('none')} data-testid='hero-block-address-btn'>
              <div className='address-icon'>
              <MapPinIcon style={{fill:'black'}}/>
              </div>
              <p className='email-text' style={{textDecoration:addressHover}}>
                3200 Howell Mill Rd NW Atlanta, GA 30327
              </p>
            </a>
          </div>
        </div>
        <div className='quiz-div'>
       
        <Quiz brochureDownload={brochureDownload} db={db}/>
        </div>
       
      </div>
    </div>
    </>}
  
   </>
 )
}