import './App.css';
import './styles/styles.css'
import {storage} from "./Firebase"
import {db} from "./Firebase"
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getDownloadURL, ref } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss';
import Home from './landing_page/home/Home';
import NotFound from './error_page/NotFound';


function App() {
  const [corsoBuilding, setCorsoBuilding]=useState()
 
  const getCorsoImg=()=>{
    getDownloadURL(ref(storage, `corso-landing-page/corso_building_lower_res.jpg`))
    .then((url) => {
      setCorsoBuilding(url)
      
    })
    .catch((error) => {
      // Handle any errors
    });
  };
  
  useEffect(()=>{
    getCorsoImg()
    
  },[])

 return (
   <div className="App">
    <BrowserRouter >
   
        <Routes>
        <Route path="/" element={ <Home storage={storage} db={db}/>}/>
        <Route path="/home" element={ <Home storage={storage} db={db}/>}/>
        <Route path='*' element={<NotFound />}/>
        </Routes>
      
    </BrowserRouter>
     
  
   </div>
 );
}


export default App;



